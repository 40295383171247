import Handlebars from 'handlebars';

// TODO: this is dangerous and not flexible solution, think about using nesting structure of Contentful
// TODO: Tooltip should have common styles classes, independent of solar and any other stuff
Handlebars.registerHelper(
    'tooltip',
    data => new Handlebars.SafeString(
        `<Tooltip className="ltn-de-solar-characteristic__info-icon ltn-de-solar-characteristic__info-icon_color_gray" text='${data}' />`
    )
);

export function compileHandlebars(source, data) {
    if (source && typeof source === 'string' && typeof data === 'object') {
        const template = Handlebars.compile(source, { noEscape: true });
        return template(data);
    }
    return source;
}
